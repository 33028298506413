.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .trainer-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      width: 10%;
      height: 240px;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      margin: 0 7.5px 15px;
      background-position: center;
      background-size: cover;

      &:first-child {
        margin-right: 7.5px;
      }

      &:last-child {
        margin-left: 7.5px;
      }

      .content {
        height: 90px;
        padding: 0 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #2827258c;

        .title {
          font-weight: bold;
          margin-bottom: 6px;
          font-size: 22px;
          color: #fff;
        }

        .subtitle {
          font-weight: lighter;
          font-size: 16px;
        }
      }
    }
  }
}
