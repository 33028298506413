.testimonials {
  height: auto;
  width: 100%;
  background-color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 30px;
    line-height: initial;
  }

  .content {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    margin: 100px auto;
    justify-content: center;

    .image-container {
      position: relative;
      background-color: #52c41a;
    }

    .face-img {
      width: 100px;
      height: 100px;
      border-radius: 50px;
      position: absolute;
      top: -120px;
      left: 40px;
      border: 2px solid #60b98c;
    }

    .content-item {
      width: 300px;
      height: 240px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #60b98c;
      border-radius: 10px;
      flex-direction: column;
      margin: 0 7.5px 15px;

      h3 {
        font-size: 14px;
        color: #4a4a4a;
      }
    }
  }
}
