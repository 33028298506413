@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');

$font-primary-bold: normal normal bold 70px/86px Montserrat Alternates;
$font-secondary: normal normal 500 32px/37px Montserrat Alternates;

$sans-serif: 'Source Sans Pro', Helvetica, Verdana, sans-serif;
$serif: Merriweather, Georgia, 'Times New Roman', serif;
$mono: 'Source Code Pro', Courier, mono;

$light: 300;
$normal: 400;
$semibold: 500;
$bold: 700;

$large: 50px;
$medium: 30px;
$small: 20px;
$font-size-base: 14px; // major text font size
