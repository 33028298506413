@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap);
/* Document
 * ========================================================================== *//**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}/* Sections
 * ========================================================================== *//**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */h1 {
  font-size: 2em;
  margin: 0.67em 0;
}/* Grouping content
 * ========================================================================== *//**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}/**
 * Add the correct display in IE.
 */main {
  display: block;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/* Text-level semantics
 * ========================================================================== *//**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */b,
strong {
  font-weight: bolder;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/**
 * Add the correct font size in all browsers.
 */small {
  font-size: 80%;
}/* Embedded content
 * ========================================================================== *//**
 * Hide the overflow in IE.
 */svg:not(:root) {
  overflow: hidden;
}/* Forms
 * ========================================================================== *//**
 * Remove the margin on controls in Safari.
 */button,
input,
select {
  margin: 0;
}/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}/**
 * Correct the inability to style buttons in iOS and Safari.
 */button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}/**
 * Correct the padding in Firefox.
 */fieldset {
  padding: 0.35em 0.75em 0.625em;
}/**
 * Show the overflow in Edge 18- and IE.
 */input {
  overflow: visible;
}/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}/**
 * Remove the inheritance of text transform in Firefox.
 */select {
  text-transform: none;
}/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */::-webkit-search-decoration {
  -webkit-appearance: none;
}/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */::-moz-focus-inner {
  border-style: none;
  padding: 0;
}/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */:-moz-focusring {
  outline: 1px dotted ButtonText;
}/**
 * Remove the additional :invalid styles in Firefox.
 */:-moz-ui-invalid {
  box-shadow: none;
}/* Interactive
 * ========================================================================== *//*
 * Add the correct display in Edge 18- and IE.
 */details {
  display: block;
}/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}dialog:not([open]) {
  display: none;
}/*
 * Add the correct display in all browsers.
 */summary {
  display: list-item;
}/* Scripting
 * ========================================================================== *//**
 * Add the correct display in IE.
 */template {
  display: none;
}/* User interaction
 * ========================================================================== */body{font-family:"Source Sans Pro",Helvetica,Verdana,sans-serif;font-weight:500;color:rgba(0,0,0,0.65);background:#fff;margin:0;padding:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;-webkit-text-size-adjust:100%}.cls-1{fill:#f1f2f2}.cls-2{fill:#bcbec0}.cls-3{fill:#fff}.cls-4{fill:#facc43}.cls-5{fill:#394352}.cls-6{opacity:0.18}.cls-7{fill:#374353}.cls-8{fill:#fffbe3}.cls-9{fill:#1e120d}.cls-10{fill:#f2906d}a{text-decoration:none;color:rgba(0,0,0,0.65)}h1{font:normal normal bold 70px/86px Montserrat Alternates;font-size:50px}h2{font:normal normal 500 32px/37px Montserrat Alternates;font-size:20px}ul,li{list-style:none;margin:0;padding:0}li{font:normal normal 500 32px/37px Montserrat Alternates;font-size:14px}.app-container{background-color:#fff;display:flex;flex-direction:column;justify-content:center}.app-content{align-self:center;background-color:#fff;width:100%}@media (max-width: 800px){.app-content{width:100%;margin:0;border-radius:0}}

.container{background-color:#fff;display:flex;flex-direction:column;align-items:center}.container .nav-title-container{flex-direction:row;display:flex;justify-content:center}.container .section-secondary{width:100%;display:flex;flex-direction:column}.container .section-secondary .center{align-self:center;text-align:center;font-size:30px}.container .section-secondary .option{align-self:center;margin-left:80px;font-size:16px;font-weight:bold;font-family:normal normal 500 32px/37px Montserrat Alternates}.container .footer{padding-bottom:50px;height:auto;width:100%;background-color:#60b98c;display:flex;flex-direction:column}

.section-primary{height:auto;background-color:#ffea75;border-radius:20px;width:70%;padding:10px}.section-primary .inner{padding:20px 40px;display:flex;flex-direction:row;justify-content:space-around;align-content:center;align-items:center}.section-primary .inner .title{line-height:0}.section-primary .inner .sub-title{line-height:normal;line-height:initial}.section-primary .inner .custom-line-height{line-height:5px}.section-primary .home-img{width:400px;height:400px}

.menu{width:100%;display:flex;flex-wrap:wrap;justify-content:space-between}.menu .item{min-width:30%;height:240px;flex:1 1 auto;display:flex;align-items:center;justify-content:center;border-radius:10px;margin:0 7.5px 15px;background-position:center;background-size:cover}.menu .item.large{height:300px}.menu .item.small-image{width:10%}.menu .item:first-child{margin-right:7.5px}.menu .item:last-child{margin-left:7.5px}.menu .item .content{height:90px;padding:0 35px;display:flex;flex-direction:column;align-items:center;justify-content:center;background-color:#2827258c}.menu .item .content .title{font-weight:bold;margin-bottom:6px;font-size:22px;color:#fff}.menu .item .content .subtitle{font-weight:lighter;font-size:16px}

.container{background-color:#fff;display:flex;flex-direction:column;align-items:center}.container .trainer-list{width:100%;display:flex;flex-wrap:wrap;justify-content:space-between}.container .trainer-list .item{width:10%;height:240px;flex:1 1 auto;display:flex;align-items:center;justify-content:center;border-radius:10px;margin:0 7.5px 15px;background-position:center;background-size:cover}.container .trainer-list .item:first-child{margin-right:7.5px}.container .trainer-list .item:last-child{margin-left:7.5px}.container .trainer-list .item .content{height:90px;padding:0 35px;display:flex;flex-direction:column;align-items:center;justify-content:center;background-color:#2827258c}.container .trainer-list .item .content .title{font-weight:bold;margin-bottom:6px;font-size:22px;color:#fff}.container .trainer-list .item .content .subtitle{font-weight:lighter;font-size:16px}

.testimonials{height:auto;width:100%;background-color:#fff;text-align:center;display:flex;flex-direction:column}.testimonials h1{font-size:30px;line-height:normal;line-height:initial}.testimonials .content{width:60%;display:flex;flex-wrap:wrap;margin:100px auto;justify-content:center}.testimonials .content .image-container{position:relative;background-color:#52c41a}.testimonials .content .face-img{width:100px;height:100px;border-radius:50px;position:absolute;top:-120px;left:40px;border:2px solid #60b98c}.testimonials .content .content-item{width:300px;height:240px;display:flex;align-items:center;justify-content:center;border:2px solid #60b98c;border-radius:10px;flex-direction:column;margin:0 7.5px 15px}.testimonials .content .content-item h3{font-size:14px;color:#4a4a4a}

.full-width{margin-top:50px;padding-bottom:50px;height:auto;width:100%;background-color:#f7f1e6;text-align:center}.full-width h1{font-size:30px;line-height:normal;line-height:initial}.full-width h2{font-size:18px;line-height:normal;line-height:initial}.full-width .content{display:flex;flex-wrap:wrap;width:60%;margin:0 auto;justify-content:center}.full-width .content .content-item{min-width:50%;display:flex;justify-content:center;flex-direction:column}.full-width .content .content-item:first-child{justify-content:center}.full-width .content .content-item .home-img{width:400px;height:400px;margin:0 auto}.full-width .content .content-item li{text-align:left;text-decoration:none;font-size:16px;color:rgba(0,0,0,0.85)}

.map-container{width:100%}

.trainers{flex-direction:row;display:flex}

.header{height:70px;width:70%;display:flex;justify-content:space-between;margin:0 auto}.header .logo{height:100%;width:240px;padding:10px}.header .options{width:50%;height:100%;display:flex;align-items:center;justify-content:flex-end}.header .options .option{padding:10px 15px;font:normal normal bold 70px/86px Montserrat Alternates;font-size:14px;color:rgba(0,0,0,0.85)}

.container-profile{display:flex;flex-direction:column;width:60%;text-align:center;margin:50px auto}.container-profile .title{font-size:26px}.container-profile .logout-container{width:300px;margin:0 auto}.container-profile .menu{width:100%;display:flex;flex-wrap:wrap;justify-content:space-between;margin:0 auto}.container-profile .menu .item{box-shadow:0 0 8px #ccc;background-color:#fff;width:30%;min-height:140px;flex:1 1 auto;display:flex;align-items:center;justify-content:center;border-radius:10px;margin:0 7.5px 15px}.container-profile .menu .item:first-child{margin-right:7.5px}.container-profile .menu .item:last-child{margin-left:7.5px}.container-profile .menu .item .content{height:90px;padding:0 35px;display:flex;flex-direction:column;align-items:center;justify-content:center;background-color:#fff}.container-profile .menu .item .content .title{font-weight:bold;margin-bottom:6px;font-size:20px;color:rgba(0,0,0,0.65);font-family:'Montserrat', sans-serif}.container-profile .menu .item .content .subtitle{font-weight:lighter;font-size:16px;font-family:'Montserrat', sans-serif;text-align:center}

.container-trainer{display:flex;flex-direction:column;width:60%;text-align:center;margin:50px auto}.container-trainer .header-trainer{width:100%;flex-direction:row;justify-content:space-between;display:flex}.container-trainer .title{font-weight:bold;font-size:20px;color:rgba(0,0,0,0.65);font-family:'Montserrat', sans-serif}.container-trainer .menu{width:100%;display:flex}.container-trainer .menu .item{box-shadow:0 0 8px #ccc;background-color:#fff;width:100%;min-height:140px;display:flex;align-items:center;justify-content:center;border-radius:10px}.container-trainer .menu .item .content{height:90px;padding:0 35px;display:flex;flex-direction:column;align-items:center;justify-content:center;background-color:#fff}.container-trainer .menu .item .content .title{font-weight:bold;margin-bottom:6px;font-size:20px;color:rgba(0,0,0,0.65);font-family:'Montserrat', sans-serif}.container-trainer .menu .item .content .subtitle{font-weight:lighter;font-size:16px;font-family:'Montserrat', sans-serif;text-align:center}

