@import '../../styles/colors';

.container-trainer {
  display: flex;
  flex-direction: column;
  width: 60%;
  text-align: center;
  margin: 50px auto;

  .header-trainer {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }

  .title {
    font-weight: bold;
    font-size: 20px;
    color: $text-color;
    font-family: 'Montserrat', sans-serif;
  }

  .menu {
    width: 100%;
    display: flex;

    .item {
      -moz-box-shadow: 0 0 8px #ccc;
      -webkit-box-shadow: 0 0 8px #ccc;
      box-shadow: 0 0 8px #ccc;
      background-color: #fff;
      width: 100%;
      min-height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      .content {
        height: 90px;
        padding: 0 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        .title {
          font-weight: bold;
          margin-bottom: 6px;
          font-size: 20px;
          color: $text-color;
          font-family: 'Montserrat', sans-serif;
        }

        .subtitle {
          font-weight: lighter;
          font-size: 16px;
          font-family: 'Montserrat', sans-serif;
          text-align: center;
        }
      }
    }
  }
}
