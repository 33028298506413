@import '../../styles/typography';
@import '../../styles/colors';

.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .nav-title-container {
    flex-direction: row;
    display: flex;
    justify-content: center;
  }

  .section-secondary {
    width: 100%;
    display: flex;
    flex-direction: column;

    .center {
      align-self: center;
      text-align: center;
      font-size: $medium;
    }

    .option {
      align-self: center;
      margin-left: 80px;
      font-size: 16px;
      font-weight: bold;
      font-family: $font-secondary;
    }
  }

  .footer {
    padding-bottom: 50px;
    height: auto;
    width: 100%;
    background-color: #60b98c;
    display: flex;
    flex-direction: column;
  }
}
