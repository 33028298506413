@import-normalize; /* bring in normalize.css styles */
@import '~antd/dist/antd.css';
@import 'typography';
@import 'colors';

body {
  font-family: $sans-serif;
  font-weight: $semibold;
  color: $text-color;
  background: $body-bg-color;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%; //fix for iOS
}

// Logo svg styling
.cls-1 {
  fill: #f1f2f2;
}
.cls-2 {
  fill: #bcbec0;
}
.cls-3 {
  fill: #fff;
}
.cls-4 {
  fill: #facc43;
}
.cls-5 {
  fill: #394352;
}
.cls-6 {
  opacity: 0.18;
}
.cls-7 {
  fill: #374353;
}
.cls-8 {
  fill: #fffbe3;
}
.cls-9 {
  fill: #1e120d;
}
.cls-10 {
  fill: #f2906d;
}
// end of Logo svg styling

a {
  text-decoration: none;
  color: $text-color;
}

h1 {
  font: $font-primary-bold;
  font-size: $large;
}

h2 {
  font: $font-secondary;
  font-size: $small;
}

//h3 {
//  font: $font-secondary;
//  font-size: 10px;
//}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  font: $font-secondary;
  font-size: $font-size-base;
}

.app-container {
  //background-color: #79dbb2;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app-content {
  align-self: center;
  background-color: #fff;
  // TODO: add media queries, if small, medium width can be 100%
  width: 100%;
  //margin: 20px;
  //border-radius: 10px;
}

@media (max-width: 800px) {
  .app-content {
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
}
