@import '../../../../styles/colors';

.full-width {
  margin-top: 50px;
  padding-bottom: 50px;
  height: auto;
  width: 100%;
  background-color: #f7f1e6;
  text-align: center;

  h1 {
    font-size: 30px;
    line-height: initial;
  }

  h2 {
    font-size: 18px;
    line-height: initial;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    margin: 0 auto;
    justify-content: center;

    .content-item {
      min-width: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      &:first-child {
        justify-content: center;
      }

      .home-img {
        width: 400px;
        height: 400px;
        margin: 0 auto;
      }

      li {
        text-align: left;
        text-decoration: none;
        font-size: 16px;
        color: $heading-color;
      }
    }
  }
}
