@import '../../styles/colors';

.container-profile {
  display: flex;
  flex-direction: column;
  width: 60%;
  text-align: center;
  margin: 50px auto;

  .title {
    font-size: 26px;
  }

  .logout-container {
    width: 300px;
    margin: 0 auto;
  }

  .menu {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;

    .item {
      -moz-box-shadow: 0 0 8px #ccc;
      -webkit-box-shadow: 0 0 8px #ccc;
      box-shadow: 0 0 8px #ccc;
      background-color: #fff;
      width: 30%;
      min-height: 140px;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      margin: 0 7.5px 15px;

      &:first-child {
        margin-right: 7.5px;
      }

      &:last-child {
        margin-left: 7.5px;
      }

      .content {
        height: 90px;
        padding: 0 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        .title {
          font-weight: bold;
          margin-bottom: 6px;
          font-size: 20px;
          color: $text-color;
          font-family: 'Montserrat', sans-serif;
        }

        .subtitle {
          font-weight: lighter;
          font-size: 16px;
          font-family: 'Montserrat', sans-serif;
          text-align: center;
        }
      }
    }
  }
}
