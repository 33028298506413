.section-primary {
  height: auto;
  background-color: #ffea75;
  border-radius: 20px;
  width: 70%;
  padding: 10px;

  .inner {
    padding: 20px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    align-items: center;

    .title {
      line-height: 0;
    }

    .sub-title {
      line-height: initial;
    }

    .custom-line-height {
      line-height: 5px;
    }
  }

  .home-img {
    width: 400px;
    height: 400px;
  }
}
