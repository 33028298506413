@import '../../../styles/typography';
@import '../../../styles/colors';

.header {
  height: 70px;
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  .logo {
    height: 100%;
    width: 240px;
    padding: 10px;
  }

  .options {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .option {
      padding: 10px 15px;
      font: $font-primary-bold;
      font-size: $font-size-base;
      color: $heading-color;
    }
  }
}
